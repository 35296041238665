@import "tailwind.css";
@import "sweetsans.css";

html,
body {
  background-color: #F2F2F2;
}

* {
  font-family: "Sweet Sans Pro";
}

button:disabled {
  cursor: not-allowed;
}

.rounded-100 {
  border-radius: 100px;
}

.hide-scroll {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.hide-scroll::-webkit-scrollbar {
  display: none;
}

.figma-shadow {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
}

input,
textarea,
select {
  appearance: none;
  -webkit-appearance: none;
  border-radius: 0;
}

textarea:focus,
input:focus,
button:focus {
  outline: none;
}

.max-h-screen-2 {
  max-height: 200vh;
}

.pt-18 {
  padding-top: 4.25rem;
}

.bottom-4\.5 {
  bottom: 1.125rem;
}

.top-21 {
  top: 5.25rem;
}

/* Animation */

@-webkit-keyframes Gradient {
  0% {
    background-position: 0% 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0% 50%
  }
}

@-moz-keyframes Gradient {
  0% {
    background-position: 0% 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0% 50%
  }
}

@keyframes Gradient {
  0% {
    background-position: 0% 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0% 50%
  }
}

.nectar-gradient-border {
  background: linear-gradient(to right, #1F1125, #1F1125),
    linear-gradient(to right, #F23900 0, #E2A8C6 50%, #F2F2F2 100%);
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  border: 2px solid transparent;
}

.nectar-results-share-gradient-border {
  background: linear-gradient(to right, #F2F2F2, #F2F2F2),
    linear-gradient(to right, #1F1125 0, #F23900 50%, #E2A8C6 100%);
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  border: 2.5px solid transparent;
}

.magnifying-glass {
  border-color: #F23900;
}

iframe {
  display: none;
}

input::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 6px;
  height: 12px;
  background: #F23900;
  cursor: pointer;
}

div::-webkit-scrollbar {
  width: 0;
  height: 0;
}
