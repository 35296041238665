@font-face {
    font-family: 'Sweet Sans Pro Hairline';
    src: url('./assets/fonts/SweetSansPro-Hairline.eot');
    src: local('Sweet Sans Pro Hairline'), local('SweetSansPro-Hairline'),
        url('./assets/fonts/SweetSansPro-Hairline.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/SweetSansPro-Hairline.woff2') format('woff2'),
        url('./assets/fonts/SweetSansPro-Hairline.woff') format('woff'),
        url('./assets/fonts/SweetSansPro-Hairline.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
  font-family: 'Garamond Premier Pro';
  src: url('./assets/fonts/garamond.otf');
  src: local('Garamond Premier Pro'), local('GaramondPremierPro'),
      url('./assets/fonts/garamond.otf') format('otf');
  font-style: italic;
  font-style: normal;
}

@font-face {
    font-family: 'Sweet Sans Pro';
    src: url('./assets/fonts/SweetSansPro-Light.eot');
    src: local('Sweet Sans Pro Light'), local('SweetSansPro-Light'),
        url('./assets/fonts/SweetSansPro-Light.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/SweetSansPro-Light.woff2') format('woff2'),
        url('./assets/fonts/SweetSansPro-Light.woff') format('woff'),
        url('./assets/fonts/SweetSansPro-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Sweet Sans Pro';
    src: url('./assets/fonts/SweetSansPro-Italic.eot');
    src: local('Sweet Sans Pro Italic'), local('SweetSansPro-Italic'),
        url('./assets/fonts/SweetSansPro-Italic.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/SweetSansPro-Italic.woff2') format('woff2'),
        url('./assets/fonts/SweetSansPro-Italic.woff') format('woff'),
        url('./assets/fonts/SweetSansPro-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Sweet Sans Pro';
    src: url('./assets/fonts/SweetSansPro-Thin.eot');
    src: local('Sweet Sans Pro Thin'), local('SweetSansPro-Thin'),
        url('./assets/fonts/SweetSansPro-Thin.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/SweetSansPro-Thin.woff2') format('woff2'),
        url('./assets/fonts/SweetSansPro-Thin.woff') format('woff'),
        url('./assets/fonts/SweetSansPro-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Sweet Sans Pro';
    src: url('./assets/fonts/SweetSansPro-Medium.eot');
    src: local('Sweet Sans Pro Medium'), local('SweetSansPro-Medium'),
        url('./assets/fonts/SweetSansPro-Medium.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/SweetSansPro-Medium.woff2') format('woff2'),
        url('./assets/fonts/SweetSansPro-Medium.woff') format('woff'),
        url('./assets/fonts/SweetSansPro-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Sweet Sans Pro';
    src: url('./assets/fonts/SweetSansPro-HeavyItalic.eot');
    src: local('Sweet Sans Pro Heavy Italic'), local('SweetSansPro-HeavyItalic'),
        url('./assets/fonts/SweetSansPro-HeavyItalic.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/SweetSansPro-HeavyItalic.woff2') format('woff2'),
        url('./assets/fonts/SweetSansPro-HeavyItalic.woff') format('woff'),
        url('./assets/fonts/SweetSansPro-HeavyItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Sweet Sans Pro';
    src: url('./assets/fonts/SweetSansPro-Regular.eot');
    src: local('Sweet Sans Pro'), local('SweetSansPro-Regular'),
        url('./assets/fonts/SweetSansPro-Regular.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/SweetSansPro-Regular.woff2') format('woff2'),
        url('./assets/fonts/SweetSansPro-Regular.woff') format('woff'),
        url('./assets/fonts/SweetSansPro-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Sweet Sans Pro';
    src: url('./assets/fonts/SweetSansPro-MediumItalic.eot');
    src: local('Sweet Sans Pro Medium Italic'), local('SweetSansPro-MediumItalic'),
        url('./assets/fonts/SweetSansPro-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/SweetSansPro-MediumItalic.woff2') format('woff2'),
        url('./assets/fonts/SweetSansPro-MediumItalic.woff') format('woff'),
        url('./assets/fonts/SweetSansPro-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Sweet Sans Pro ExThin';
    src: url('./assets/fonts/SweetSansPro-ExThin.eot');
    src: local('Sweet Sans Pro Extra Thin'), local('SweetSansPro-ExThin'),
        url('./assets/fonts/SweetSansPro-ExThin.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/SweetSansPro-ExThin.woff2') format('woff2'),
        url('./assets/fonts/SweetSansPro-ExThin.woff') format('woff'),
        url('./assets/fonts/SweetSansPro-ExThin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Sweet Sans Pro ExLight';
    src: url('./assets/fonts/SweetSansPro-ExLightItalic.eot');
    src: local('Sweet Sans Pro Extra Light Italic'), local('SweetSansPro-ExLightItalic'),
        url('./assets/fonts/SweetSansPro-ExLightItalic.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/SweetSansPro-ExLightItalic.woff2') format('woff2'),
        url('./assets/fonts/SweetSansPro-ExLightItalic.woff') format('woff'),
        url('./assets/fonts/SweetSansPro-ExLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Sweet Sans Pro';
    src: url('./assets/fonts/SweetSansPro-ThinItalic.eot');
    src: local('Sweet Sans Pro Thin Italic'), local('SweetSansPro-ThinItalic'),
        url('./assets/fonts/SweetSansPro-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/SweetSansPro-ThinItalic.woff2') format('woff2'),
        url('./assets/fonts/SweetSansPro-ThinItalic.woff') format('woff'),
        url('./assets/fonts/SweetSansPro-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Sweet Sans Pro ExThin';
    src: url('./assets/fonts/SweetSansPro-ExThinItalic.eot');
    src: local('Sweet Sans Pro Extra Thin Italic'), local('SweetSansPro-ExThinItalic'),
        url('./assets/fonts/SweetSansPro-ExThinItalic.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/SweetSansPro-ExThinItalic.woff2') format('woff2'),
        url('./assets/fonts/SweetSansPro-ExThinItalic.woff') format('woff'),
        url('./assets/fonts/SweetSansPro-ExThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Sweet Sans Pro';
    src: url('./assets/fonts/SweetSansPro-LightItalic.eot');
    src: local('Sweet Sans Pro Light Italic'), local('SweetSansPro-LightItalic'),
        url('./assets/fonts/SweetSansPro-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/SweetSansPro-LightItalic.woff2') format('woff2'),
        url('./assets/fonts/SweetSansPro-LightItalic.woff') format('woff'),
        url('./assets/fonts/SweetSansPro-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Sweet Sans Pro';
    src: url('./assets/fonts/SweetSansPro-Heavy.eot');
    src: local('Sweet Sans Pro Heavy'), local('SweetSansPro-Heavy'),
        url('./assets/fonts/SweetSansPro-Heavy.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/SweetSansPro-Heavy.woff2') format('woff2'),
        url('./assets/fonts/SweetSansPro-Heavy.woff') format('woff'),
        url('./assets/fonts/SweetSansPro-Heavy.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Sweet Sans Pro ExLight';
    src: url('./assets/fonts/SweetSansPro-ExLight.eot');
    src: local('Sweet Sans Pro Extra Light'), local('SweetSansPro-ExLight'),
        url('./assets/fonts/SweetSansPro-ExLight.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/SweetSansPro-ExLight.woff2') format('woff2'),
        url('./assets/fonts/SweetSansPro-ExLight.woff') format('woff'),
        url('./assets/fonts/SweetSansPro-ExLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Sweet Sans Pro Hairline';
    src: url('./assets/fonts/SweetSansPro-HairlineItalic.eot');
    src: local('Sweet Sans Pro Hairline Italic'), local('SweetSansPro-HairlineItalic'),
        url('./assets/fonts/SweetSansPro-HairlineItalic.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/SweetSansPro-HairlineItalic.woff2') format('woff2'),
        url('./assets/fonts/SweetSansPro-HairlineItalic.woff') format('woff'),
        url('./assets/fonts/SweetSansPro-HairlineItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Sweet Sans Pro';
    src: url('./assets/fonts/SweetSansPro-Bold.eot');
    src: local('Sweet Sans Pro Bold'), local('SweetSansPro-Bold'),
        url('./assets/fonts/SweetSansPro-Bold.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/SweetSansPro-Bold.woff2') format('woff2'),
        url('./assets/fonts/SweetSansPro-Bold.woff') format('woff'),
        url('./assets/fonts/SweetSansPro-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Sweet Sans Pro';
    src: url('./assets/fonts/SweetSansPro-BoldItalic.eot');
    src: local('Sweet Sans Pro Bold Italic'), local('SweetSansPro-BoldItalic'),
        url('./assets/fonts/SweetSansPro-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/SweetSansPro-BoldItalic.woff2') format('woff2'),
        url('./assets/fonts/SweetSansPro-BoldItalic.woff') format('woff'),
        url('./assets/fonts/SweetSansPro-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

